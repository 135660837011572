import React, { useEffect, useRef, useState } from "react"
import ButtonOutline from "../ButtonOutline"
import Input from "../Input"
import "./ModalBusiness.scss"
function ModalBussiness(props) {
  const { setShow } = props
  const ref = useRef()
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    suburb: "",
    state: "",
    question1: "",
    question2: ""
  })
  const onChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }
  const onClose = () => {
    setShow(false)
    setForm({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      suburb: "",
      state: "",
      question1: "",
      question2: ""
    })
  }
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
  return (
    <div className="modal-business">
      <div className="modal-business-form-wrapper" ref={ref}>
        <div className="modal-business-close-btn" onClick={onClose}>
          x
        </div>
        <h2>Join the Team</h2>
        <form className="modal-business-form">
          <div className="modal-business-input-wrapper">
            <Input
              type="text"
              name={"firstName"}
              placeholder="First Name*"
              onChange={onChange}
              value={form.firstName}
            />
          </div>
          <div className="modal-business-input-wrapper">
            <Input
              type="text"
              name={"lastName"}
              placeholder="Last Name*"
              onChange={onChange}
              value={form.lastName}
            />
          </div>
          <div className="modal-business-input-wrapper">
            <Input
              type="text"
              name={"email"}
              placeholder="Email*"
              onChange={onChange}
              value={form.email}
            />
          </div>
          <div className="modal-business-input-wrapper">
            <Input
              type="number"
              name={"phone"}
              placeholder="Phone*"
              onChange={onChange}
              value={form.phone}
            />
          </div>
          <div className="modal-business-input-wrapper">
            <Input
              type="text"
              name={"suburb"}
              placeholder="Suburb*"
              onChange={onChange}
              value={form.suburb}
            />
          </div>
          <div className="modal-business-input-wrapper">
            <Input
              type="text"
              name={"state"}
              placeholder="State*"
              onChange={onChange}
              value={form.state}
            />
          </div>
          <textarea
            name="question1"
            id=""
            className="modal-business-textarea"
            placeholder="How did you hear about Backa?"
            onChange={onChange}
            value={form.question1}
          ></textarea>
          <textarea
            name="question2"
            id=""
            className="modal-business-textarea"
            placeholder="What market have you identified as a market to sell at?"
            onChange={onChange}
            value={form.question2}
          ></textarea>
        </form>
        <ButtonOutline>
          Send
        </ButtonOutline>
      </div>
    </div>
  )
}

export default ModalBussiness
