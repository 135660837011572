import React, { useState } from 'react'
import AboutHeading from '../components/AboutHeading'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import Background from "../components/images/banner.jpg"
import { graphql } from 'gatsby'
import { useSeo } from '../hooks/useSeo'
import Seo from '../components/seo'
import ModalBusiness from '../components/ModalBusiness'

function BusinessOpportunitiesPage ( {data }) {
    const [show,setShow] = useState(false)
    const aboutContent = data && data.allWpAbout.edges.length >=1
    ? data.allWpAbout.edges[0].node.acf : null
    
    const banner = aboutContent.component.filter(ab => ab.fieldGroupName === "about_Acf_Component_MainBanner")
    const textWithMedia = aboutContent.component.filter(ab => ab.fieldGroupName === "about_Acf_Component_TextWithMedia")
    const isJoin = true;
    const {metaDescription,title} = useSeo("cG9zdDoxMjY5Nw==")
    return (
        <Layout>
            <Seo title={title} description={metaDescription}/>
            <Banner 
                banner={banner}
                btnNext={true}
            />
            <AboutHeading
                isJoin={isJoin}
                textWithMedia = {textWithMedia}
                className="type-second"
                setShow={setShow}
            />
            {show && <ModalBusiness setShow={setShow}/>}
        </Layout>
    )
}

export default BusinessOpportunitiesPage

export const GET_ABOUT_US = graphql`
    query getBusiness {
        allWpAbout(filter: {
            slug: {
                eq: "business-opportunities"
            }
        }) {
            edges {
                node {
                    title
                    slug
                    acf {
                        component {
                            ... on WpAbout_Acf_Component_MainBanner {
                                fieldGroupName
                                heading
                                text
                                image {
                                    sourceUrl
                                }
                            }
                            ... on WpAbout_Acf_Component_TextWithMedia {
                                fieldGroupName
                                subHeading
                                heading
                                text
                                media {
                                    mediaItemUrl
                                    mimeType
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`